import React, { Component } from 'react';
import { Route, Redirect, Router } from 'react-router-dom';
import history from './history';
import BreedRecognition from './modules/breed-recognition/views/BreedRecognition';

export default class Routes extends Component {
  render () {
    return (
      <Router history={history}>
        <div>
          <Redirect from="/" to="/breed-recognition/dogs" />
          <Route path="/breed-recognition/dogs" exact component={(props) => <BreedRecognition />} />
        </div>
      </Router>
    )
  }
}

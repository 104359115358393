import { Card, CardContent, CircularProgress, Typography } from '@material-ui/core';
import _ from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloudUpload from '@material-ui/icons/CloudUpload';
import axios from 'axios';
import React, { Component } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { CONFIG } from '../../../constants';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #A02D71;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100%;

    &>div {
      width: 100%;
    }
  }

  input {
    display: none;
  }
`

const ContentWrapper = styled.div`
  align-self: stretch;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 345px;
  box-sizing: border-box;
  margin: 0 auto;
`

const IconUploadArea = styled.label`
  height: 270px;
  cursor: pointer;
  opacity: ${props => props.active ? 0.8 : 1};
  background-color: #C4C4C5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${props => props.img ? `url(${props.img})` : ''};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &:hover {
    opacity: 0.8;
  }
`

const BarItem = styled.div`
  margin-top: ${props => props.isFirst ? 0 : `24px`};
`

export default class BreedRecognition extends Component {

  constructor(props) {
    super(props);

    this.state = {
      active: false,
      imageSrc: '',
      loading: false,
      resulting: false,
      results: []
    }

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }

  onDragEnter (e) {
    this.setState({ active: true });
  }

  onDragLeave (e) {
    this.setState({ active: false });
  }

  onDragOver (e) {
    e.preventDefault();
  }

  onDrop (e) {
    e.preventDefault();
    this.setState({ active: false });
    this.onFileChange(e, e.dataTransfer.files[0]);
  }

  async uploadFile (file) {
    const data = new FormData();
    data.append('file', file, file.name)

    return axios
      .post(`${CONFIG.API_URL}/v1/breeds/recognition/dogs`, data)
      .then(response => response.data)
  }

  async onFileChange (e, file) {
    file = file || e.target.files[0]
    let pattern = /image-*/
    let reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('Formato inválido');
      return;
    }

    this.setState({
      loading: true,
      resulting: true
    });

    reader.onload = (e) => {
      this.setState({
        imageSrc: reader.result,
        loading: false
      });
    }

    reader.readAsDataURL(file);
    let results = await this.uploadFile(file)
    results = _.take(_.orderBy(results.filter(r => !!r.breed), 'percentage', 'desc'), 4);

    this.setState({
      results,
      resulting: false
    })
  }

  render () {
    return (
      <ContentWrapper>
        <GlobalStyle />
        <Card>
          <CardContent>
            <Typography component="h5" variant="h5">Identificar raça de cão</Typography>
          </CardContent>
          <IconUploadArea
            active={this.state.active}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
            img={this.state.imageSrc}
          >
            {this.state.loading && (
              <CircularProgress />
            )}
            {!this.state.loading && (
              <div>
                {!this.state.imageSrc && (
                  <div>
                    <CloudUpload style={{ width: 42, height: 42 }} color={'action'} />
                    <Typography align={'center'} gutterBottom component="h2">Arraste ou selecione o arquivo</Typography>
                  </div>
                )}
              </div>
            )}
            <input type="file" accept="image/*" onChange={this.onFileChange} ref="input" />
          </IconUploadArea>
        </Card>
        <br />
        {!this.state.resulting && !this.state.results.length && this.state.imageSrc && (
          <Card>
            <CardContent>
              <Typography variant={'subheading'} align={'center'}>Não foi possível identificar a raça do cão.</Typography>
            </CardContent>
          </Card>
        )}
        {(this.state.resulting || this.state.results.length > 0) && (
          <Card>
            <CardContent>
              <Typography component="h5" variant="h5" paragraph>{this.state.resulting ? 'Apurando resultado...' : 'Resultado'}</Typography>
              {this.state.resulting && (
                <LinearProgress variant={'indeterminate'} />
              )}
              {!this.state.resulting && this.state.results.length > 0 && (
                <div>
                  {this.state.results.map((r, index) => (
                    <BarItem key={index} isFirst={index === 0}>
                      <Typography gutterBottom color={'textPrimary'}>
                        {r.percentage}% {r.breed.subSpecie}
                      </Typography>
                      <LinearProgress variant="determinate" value={r.percentage} />
                    </BarItem>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        )}
      </ContentWrapper>
    )
  }
}
